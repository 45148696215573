import React, { useState } from 'react'

import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap'
import { BE_HOST } from '../../constants'
import apiAxios from '../../axios/axios'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import FormInput from '../UI/FormInput'
import { RiLockPasswordLine } from 'react-icons/ri'

const ForgotPasswordVerify = () => {
const [searchParams] = useSearchParams();
const navigate = useNavigate()
    const [newPassword, setNewPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState({})
    const { verificationToken } = useParams()
    const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", message: "" })


    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setNewPassword(value)
        setStatus(prevState => { return { ...prevState, [name]: value !== "" ? "" : [null, "This field is required"] } })
    }


    const changePassword = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (newPassword === "") {
            setStatus(["", "This field is required"])
            setLoading(false);
            return;
        }

        try {
            const res = await apiAxios.post(`/account/auth/forgot-password/${verificationToken}`, {newPassword});
            if (res.status === 200) {
                setTimeout(() => {
                window.location.href = searchParams.get('redirect') ? searchParams.get('redirect') : "/auth";
                }, 3000);
            } else {
                const er = res.data?.errors;
                const errors = Object.fromEntries(
                    Object.entries(er).map(([key, value]) => [key, [null, value]])
                );
                setLoading(false);
                errors.newPassword && setStatus(errors)
            }

            setToasterState({ isOpen: true, varient: res.data.success ? "success" : "danger", message: res.data.message })
        

        } catch (error) {

        }
    }

    return (
        <>
            <Card className='rounded mb-3'>
                <Card.Body className='rounded'>
                    <div className='logo text-center m-auto'>
                        <img src={BE_HOST + '/assets/ck/collegekatta-logo-colored.png'} alt="collegekatta.in" width="75%" classes="m-auto" />
                    </div>
                    <hr className='hrspan' />
                    {toasterState.isOpen &&
                            <Alert className='py-2' variant={toasterState.varient}>
                                {toasterState.message}
                            </Alert>
                        }
                    <Form method='post' onSubmit={changePassword} >

                            <FormInput
                                type={"password"}
                                name={"newPassword"}
                                onChange={onChangeHandler}
                                value={newPassword}
                                label={<><RiLockPasswordLine /> &nbsp;Enter new password </>}
                                placeholder="**********"
                                required
                                status= {status?.newPassword}
                            />

                            <div className="text-center">
                                <Button disabled={loading && true} type="submit" className="w-75">
                                    {loading && <><Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> Loading...</>}
                                    {!loading && "Reset password"}
                                </Button>

                                <Button onClick={() => navigate("/auth")} variant='outline-success' type="button" className="w-75 my-3">
                                    Sign in
                                </Button>
                            </div>
                        </Form>
                </Card.Body>
            </Card>
        </>

    )
}


export default ForgotPasswordVerify