import React, { useCallback, useEffect, useState } from 'react'

import { Alert, Button, Card, Spinner } from 'react-bootstrap'
import { BE_HOST } from '../../constants'
import apiAxios from '../../axios/axios'
import { useNavigate, useParams } from 'react-router-dom'

const VerifyEmail = () => {
const navigate = useNavigate();
    const [isEmailVerified, setIsEmailVerified] = useState({ status: false, message: "" })
    const [loading, setLoading] = useState(true)

    const { verificationToken } = useParams()

    const validateEMail = useCallback(async () => {
        try {
            const res = await apiAxios.get(`/account/auth/verify-email/${verificationToken}`);
            setIsEmailVerified({ status: res.data?.success, message: res.data?.message })
            setLoading(false)
        } catch (error) {

        }
    }, [verificationToken])

    useEffect(() => {
        validateEMail();
    }, [validateEMail])

    return (
        <>
            <Card className='rounded mb-3'>
                <Card.Body className='rounded'>
                    <div className='logo text-center m-auto'>
                        <img src={BE_HOST + '/assets/ck/collegekatta-logo-colored.png'} alt="collegekatta.in" width="75%" classes="m-auto" />
                    </div>
                    <hr className='hrspan' />

                    <div className='d-flex align-items-center justify-content-center flex-column'>

                        {loading && <>
                            <Spinner size='sm' /> &nbsp;Please wait, validating email...</>}
                        {!loading && 
                        <>
                        <Alert variant={isEmailVerified.status ? "success" : "danger"}>
                            {isEmailVerified.message}
                        </Alert>
                        
                            <Button onClick={() => navigate("/auth")} variant='warning'>Sign in</Button>

                        </>
                        }

                    </div>
                </Card.Body>
            </Card>
        </>

    )
}


export default VerifyEmail