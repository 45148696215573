import React from 'react'

const BlogDetailView = ({ blog }) => {
  return (
    <>
      <div className='rounded m-auto' style={{height: '250px', width: "80%"}}>
        <img alt={blog.title} className='w-100 h-100 rounded' src={blog.featuredImage} />
      </div>
      <div className='mt-3' dangerouslySetInnerHTML={{ __html: blog.content }} />

    </>
  )
}

export default BlogDetailView