import React from 'react'
import { Outlet } from "react-router-dom"
import Modal from "react-bootstrap/Modal"

const Auth = () => {

    return (
        <Modal
            id="modal_login"
            show={true}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body>
                <Outlet />
            </Modal.Body>
        </Modal>
    )


}

export default Auth