import { Navigate, createBrowserRouter } from "react-router-dom";
import App from "../App";
import ErrorPage from "../components/UI/ErrorPage";
import Protected from "./ProtectedRoutes";
import Signin from "../components/auth/Signin";
import Auth from "../components/auth/Auth";
import Signup from "../components/auth/Signup";
import Layout from "../components/Layout";
import Blogs from "../components/blogs/Blogs";
import BlogsList from "../components/blogs/BlogsList";
import BlogNew from "../components/blogs/BlogNew";
import VerifyEmail from "../components/auth/VerifyEmail";
import BlogView from "../components/blogs/BlogView";
import BlogUpdate from "../components/blogs/BlogUpdate";
import Profile from "../components/profile/Profile";
import ForgotPassword from "../components/auth/ForgotPassword";
import ForgotPasswordVerify from "../components/auth/ForgotPasswordVerify";
import Categories from "../components/blogs/Categories";
import Admin from "../components/admin/Admin";
import Dashboard from "../components/admin/Dashboard";


const router = createBrowserRouter([
    {
        path: "",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "",
                element: (<Protected authentication><Layout /></Protected>),
                children: [
                    {
                        path: "",
                        element: <Navigate replace to="profile" />,

                    },
                    {
                        path: "profile",
                        element: <Profile />
                    },
                    {
                        path: "blogs",
                        element: <Blogs />,
                        children: [
                            {
                                path: "",
                                element: <Navigate replace to="list" />,
                            },
                            {
                                path: "list",
                                element: <BlogsList />,
                            },
                            {
                                path: "new",
                                element: <BlogNew />,
                            },
                            {
                                path: "view/:blogId",
                                element: <BlogView />,
                            },
                            {
                                path: "update/:blogId",
                                element: <BlogUpdate />,
                            },
                            {
                                path: "categories",
                                element: <Categories />,
                            }
                        ]
                    },
                    {
                        path: "admin",
                        element: <Admin />,
                        children: [
                            {
                                path: "",
                                element: <Dashboard />,
                            },
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: "/auth",
        element: <Auth />,
        children: [
            {
                path: "",
                element: <Signin />
            },
            {
                path: "signup",
                element: <Signup />
            },
            {
                path: "verify-email/:verificationToken",
                element: <VerifyEmail />
            },
            {
                path: "forgot-password",
                element: <ForgotPassword />
            },
            {
                path: "forgot-password/:verificationToken",
                element: <ForgotPasswordVerify />
            }

        ]
    }

])

export default router