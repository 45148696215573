import React, { useState } from 'react'
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap'
import FormInput from '../UI/FormInput'
import { BE_HOST } from '../../constants'
import apiAxios from '../../axios/axios'
import { useNavigate } from 'react-router-dom'

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState({})
    const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", message: "" })

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setEmail(value)
        setStatus(prevState => { return { ...prevState, [name]: value !== "" ? "" : [null, "This field is required"] } })
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (email === "") {
            setStatus(["", "This field is required"])
            setLoading(false);
            return;
        }

        try {
            const res = await apiAxios.post("/account/auth/forgot-password", { email });
            if (res.status === 200) {
                // setTimeout(() => {
                // window.location.href = searchParams.get('redirect') ? searchParams.get('redirect') : "/auth";
                // }, 3000);
            } else {
                const er = res.data.errors;
                const errors = Object.fromEntries(
                    Object.entries(er).map(([key, value]) => [key, [null, value]])
                );
                setLoading(false);
                setStatus(errors)
            }

            setToasterState({ isOpen: true, varient: res.data.success ? "success" : "danger", message: res.data.message })
        } catch (error) {

        }

    }

    return (
        <>
            <Card className="rounded mb-3">
                <Card.Body className='rounded'>
                    <div className='logo text-center m-auto'>
                        <img src={BE_HOST + '/assets/ck/collegekatta-logo-colored.png'} alt="collegekatta.in" width="75%" classes="m-auto" />
                        <hr className='hrspan' />

                        <h3>Reset your password</h3>

                    </div>
                    <hr className='hrspan' />
                    <div className="form-body">
                        {toasterState.isOpen &&
                            <Alert className='py-2' variant={toasterState.varient}>
                                {toasterState.message}
                            </Alert>
                        }
                        <Form method='post' onSubmit={onSubmitHandler}>

                            <FormInput
                                type={"email"}
                                name={"email"}
                                onChange={onChangeHandler}
                                value={email}
                                label={<>Enter your user account's verified email address and we will send you a password reset link. </>}
                                placeholder="email@domain.com"
                                status={status?.email}
                                required
                            />

                            <div className="text-center">
                                <Button disabled={loading && true} type="submit" className="w-75">
                                    {loading && <><Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> Loading...</>}
                                    {!loading && "Send password reset email"}
                                </Button>

                                <Button onClick={() => navigate(-1)} variant='outline-success' type="button" className="w-75 my-3">
                                    Go Back
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default ForgotPassword