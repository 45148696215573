import React, { useState } from 'react'
import { Card } from 'react-bootstrap'

import apiAxios from '../../axios/axios';
import { validateData } from '../../utils/helpers';
import { IoDocumentTextOutline } from 'react-icons/io5';
import Toaster from '../UI/Toaster';
import { useNavigate } from 'react-router-dom';
import { icon_size_20 } from '../../constants';
import BlogForm from '../UI/BlogForm';

const BlogNew = () => {
    const [loading, setLoading] = useState(false)
    const [blogDetails, setBlogDetails] = useState({ title: "", category: "", inshort: "", content: "", featuredImage: '', tags: "" });
    const [blogStatus, setBlogStatus] = useState({})
    const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", message: "" })
    const navigate = useNavigate();

    const onChangeHandler = async (e) => {
        const { name, value, type } = e.target;

        if (type === 'file') {
            setBlogDetails((prevState) => { return { ...prevState, [name]: e.target.files[0] } });
        } else {
            setBlogDetails((prevState) => { return { ...prevState, [name]: value }; });
        }
        setBlogStatus(prevState => { return { ...prevState, [name]: value !== "" ? "" : [null, "This field is required"] } })

    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const validations = validateData(blogDetails);
        if (validations) {
            setLoading(false);
            setBlogStatus(validations)
            setToasterState({ isOpen: true, varient: "danger", message: "Enter all details" })
            return;
        }

        try {
            const data = new FormData();
            for (const [key, value] of Object.entries(blogDetails)) {
                data.append(key, value);
            }

            const res = await apiAxios.post("/account/blogs", data);

            if (res.status === 200) {
                setTimeout(() => {
                    navigate("/blogs")
                }, 2000);
            } else {
                const er = res.data.errors;
                const errors = Object.fromEntries(
                    Object.entries(er).map(([key, value]) => [key, [null, value]])
                );
                setBlogStatus(errors)
            }

            setLoading(false);
            setToasterState({ isOpen: true, varient: res.data.success ? "success" : "danger", message: res.data.message })

        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <>
            {toasterState.isOpen && <Toaster showToast={toasterState} hideToast={setToasterState} />}

            <Card.Header className='bg text-info h5'><IoDocumentTextOutline style={icon_size_20} /> Write new blog</Card.Header>
            <Card.Body className='my-4'>
                <BlogForm loading={loading} blogDetails={blogDetails} blogStatus={blogStatus} onChangeHandler={onChangeHandler} onSubmitHandler={onSubmitHandler} />
            </Card.Body>

        </>
    )
}

export default BlogNew