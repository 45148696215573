import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import FormInput from './FormInput'
import { MdOutlineSubtitles, MdOutlineTextsms } from 'react-icons/md'
import { TbCategoryPlus } from 'react-icons/tb'
import { IoIosImages, IoIosPricetags } from 'react-icons/io'
import TinyMCEEditor from './TinyMCEEditor'
import { useSelector } from 'react-redux'
import { IoWarningOutline } from 'react-icons/io5'

const BlogForm = ({ loading = false, blogDetails, onChangeHandler, blogStatus, onSubmitHandler }) => {

    const editorRef = useRef(null);

    const [blogCategories, setBlogCategories] = useState({});

    const savedCategories = useSelector(state => state.blogs.savedCategories)

    const getBlogCategories = useCallback(async () => {
        if (savedCategories && savedCategories.length > 0) {
            setBlogCategories(savedCategories);
        }

    }, [savedCategories])

    useEffect(() => {

        getBlogCategories();

    }, [getBlogCategories])


    return (
        <Form method="post" autoComplete="off" onSubmit={onSubmitHandler}>
            <Row className="mb-3">
                <FormInput
                    name='title'
                    className='col'
                    label={<><MdOutlineSubtitles /> &nbsp;Blog title / heading</>}
                    value={blogDetails.title}
                    onChange={onChangeHandler}
                    status={blogStatus?.title}
                    placeholder="Enter blog title"
                />

                <Form.Group as={Col} controlId="category">
                    <Form.Label><TbCategoryPlus /> &nbsp;Select blog category</Form.Label>
                    <Form.Select onChange={onChangeHandler} value={blogDetails.category} name='category' className={`${blogStatus?.category?.[0] === null && "border-danger"}`} >
                        <option value="">--SELECT--</option>
                        {blogCategories.length && blogCategories?.map(category => <option value={category._id} key={category._id}>{category.name}</option>)}
                    </Form.Select>
                    {blogStatus?.category?.[0] === null ?
                        <Form.Text className='warning'><IoWarningOutline style={{ width: "16px", height: "16px" }} /> &nbsp;{blogStatus?.category?.[1]}</Form.Text>
                        : ""
                    }
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <FormInput
                    type='file'
                    name='featuredImage'
                    className='col'
                    label={<><IoIosImages /> &nbsp;Cover image</>}
                    onChange={onChangeHandler}
                    accept="image/*"
                    status={blogStatus?.featuredImage}
                />

                <FormInput
                    name='tags'
                    className='col'
                    label={<><IoIosPricetags /> &nbsp;Tags <small> &nbsp;(Comma separated)</small></>}
                    onChange={onChangeHandler}
                    value={blogDetails.tags}
                    status={blogStatus?.tags}
                    placeholder="Education, Sport, Programming ...etc"
                />
                <FormInput
                    name='inshort'
                    className='col-12'
                    label={<><MdOutlineTextsms /> &nbsp;Short description <small> &nbsp;(Max 160 Charecters)</small></>}
                    onChange={onChangeHandler}
                    value={blogDetails.inshort}
                    status={blogStatus?.inshort}
                    placeholder="White short descrition"
                />
            </Row>

            <Form.Group className="mt-3" controlId="content">
                <Form.Label><MdOutlineTextsms /> &nbsp;Blog content</Form.Label>
                <TinyMCEEditor
                    name="content"
                    ref={editorRef}
                    onChange={onChangeHandler}
                    status={blogStatus?.content}
                    content={blogDetails.content}
                />
            </Form.Group>

            <button disabled={loading && true} type='submit' className='btn btn-primary px-5  my-3'>

                {loading && <><Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /> Loading...</>}
                {!loading && "Upload blog"}</button>
        </Form>
    )
}

export default BlogForm