import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import apiAxios from '../../axios/axios';
import BlogDetailView from './BlogDetailView';
import Loader from '../UI/Loader';
import { MdOutlineEditNote } from 'react-icons/md';
import { icon_size_20 } from '../../constants';
import { Button, Card, Form } from 'react-bootstrap';
import ConfirmationModal from '../UI/ConfirmationModal';
import Toaster from '../UI/Toaster';

const BlogView = () => {
  const { blogId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true)
  const [blogDetails, setBlogDetails] = useState(false)
  const [modalState, setModalState] = useState({ isShow: false, id: "" })
  const [blogStatus, setBlogStatus] = useState("");
  const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", message: "" })

  const getBlog = useCallback(async () => {
    try {
      const res = await apiAxios.get(`/account/blogs/${blogId}`);

      if (res.status === 200) {
        setBlogDetails({ ...res.data?.data.blog })
        setBlogStatus(res.data?.data.blog.status)
      } else {
        console.log(res.data?.message);
      }

    } catch (error) {

    }

    setLoading(false)
  }, [blogId])

  const handleBlogStatus = (e) => {
    setBlogStatus(e.target.value);
    setModalState({ isShow: true, id: "cnfmodal" })
  }


  const changeBlogStatus = async () => {
    setLoading(true)
    try {
      const res = await apiAxios.get(`/account/blogs/${blogDetails._id}/${blogStatus}`)
      setToasterState({ isOpen: true, varient: res.data.success ? "success" : "danger", message: res.data.message })

    } catch (error) {}
    setLoading(false)
  }

  useEffect(() => {
    getBlog();
  }, [getBlog])

  return (
    <>
      {loading && <Loader />}
      {toasterState.isOpen && <Toaster showToast={toasterState} hideToast={setToasterState} />}

      <ConfirmationModal id="cnfmodal" show={modalState} onClose={setModalState} onConfirm={changeBlogStatus} >
        Are you sure you want to update the blog status?
      </ConfirmationModal>

      <Card.Header className='bg text-info h5 d-flex justify-content-between align-items-center'>
        <span className='flex-grow-1'>
          {blogDetails.title}
        </span>
        <Form.Group style={{ width: "150px" }} >
          <Form.Select onChange={handleBlogStatus} className='bg-secondary' value={blogStatus} name='blogStatus' >
            <option value="PUBLISHED">PUBLISHED</option>
            <option value="DRAFT">DRAFT</option>
            <option value="ARCHIVED">ARCHIVED</option>
          </Form.Select>
        </Form.Group>

        {blogDetails.status?.toLowerCase() !== "published" &&
          <Button title={"Edit blog"} onClick={() => navigate(`/blogs/update/${blogDetails._id}`)} variant='outline-warning' className='mx-2'>
            <MdOutlineEditNote style={icon_size_20} />
          </Button>
        }
      </Card.Header>
      <Card.Body>

        {
          !loading && blogDetails ? <BlogDetailView blog={blogDetails} /> : "No blog"
        }

      </Card.Body>

    </>
  )
}

export default BlogView