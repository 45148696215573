import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './header.css';
import LoggedInProfileNav from './LoggedInProfileNav';
import NavLi from './UI/NavLi';
import { RiPagesLine } from 'react-icons/ri';
import { BE_HOST, BLOGS_HOST, MAIN_HOST } from '../constants';
import { MdOutlineContactSupport } from 'react-icons/md';
import { IoHomeOutline } from 'react-icons/io5';

function TopHeader() {

    return (
        <>
            <Navbar fixed='top' expand={"md"} className="mb-3 top-nav py-0">
                <Container>
                    <Navbar.Brand href="/"><img alt='collegekatta' src={BE_HOST + "/assets/ck/collegekatta-logo-white.png"} width={'160px'} /></Navbar.Brand>
                    <LoggedInProfileNav classes='d-block ms-auto d-md-none' />
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"md"}`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${"md"}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${"md"}`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"md"}`}>
                                <img alt='collegekatta' src={BE_HOST + "/assets/ck/collegekatta-logo-white.png"} width={'200px'} />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>

                            <Nav className="justify-content-start flex-grow-1 pe-3">

                                <NavLi to={MAIN_HOST}>
                                    <IoHomeOutline />
                                    &nbsp; Home
                                </NavLi>

                                <NavLi to={BLOGS_HOST}>
                                    <RiPagesLine />
                                    &nbsp; Blogs
                                </NavLi>

                            </Nav>

                            <NavLi to={`${MAIN_HOST}/support`}>
                                <MdOutlineContactSupport />
                                &nbsp; Support
                            </NavLi>
                            <LoggedInProfileNav classes='d-none d-md-block ms-3' />

                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </>
    );
}

export default TopHeader;