import React, { useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import apiAxios from '../../axios/axios'
import Loader from '../UI/Loader'
import Toaster from '../UI/Toaster'

const EmailVerificationToken = ({ email }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", message: "" })
  

    const resendverifyEmail = async () => {
        setIsLoading(true);

        try {
            const res = await apiAxios.get("account/resend-verify-email");
            setToasterState({ isOpen: true, varient: res.data.success ? "success" : "danger", message: res.data.message })
           
        } catch (error) {

        }

        setIsLoading(false);
    }

    return (
        <>

            {toasterState.isOpen && <Toaster hideToast={setToasterState} showToast={toasterState} />}
            {isLoading && <Loader />}

            {
                <Alert className='py-2' variant={'danger'}>Your email id is not verified, click
                    <Button onClick={resendverifyEmail} className='mx-2' size='sm' variant='warning'>Here</Button>
                    to get email verification link!
                </Alert>
            }
        </>

    )
}


export default EmailVerificationToken