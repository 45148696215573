import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Personal from './Personal'
import Email from './Email'
import Password from './Password'
import { MdAlternateEmail, MdLockReset, MdOutlineAccountCircle } from 'react-icons/md'
import { icon_size_20 } from '../../constants'
import { RiEdit2Line } from 'react-icons/ri'
import { GiCancel } from 'react-icons/gi'

const Profile = () => {

    const userDetails = useSelector(state => state.auth.userData)
    const [isEditable, setisEditable] = useState({ personal: false, email: false, password: false })

    return (

        <>
            <Header isEditable={isEditable.personal} setisEditable={setisEditable} section={'personal'}><MdOutlineAccountCircle style={icon_size_20} /> Personal Details</Header>
            <Card.Body className='my-4'>
                <Personal setisEditable={setisEditable} isEditable={isEditable.personal} userDetails={userDetails} />
            </Card.Body>
            <Header disabled isEditable={isEditable.email} setisEditable={setisEditable} section={'email'}><MdAlternateEmail style={icon_size_20} /> Update Email</Header>
            <Card.Body className='my-4'>

                <Email setisEditable={setisEditable} isEditable={isEditable.email} email={userDetails.email} />

            </Card.Body>
            <Header isEditable={isEditable.password} setisEditable={setisEditable} section={'password'}><MdLockReset style={icon_size_20} /> Update Password</Header>
            <Card.Body className='my-4'>
                <Password setisEditable={setisEditable} isEditable={isEditable.password} />
            </Card.Body>
        </>
    )
}

export default Profile


const Header = ({ isEditable, setisEditable, section, disabled = false, children }) => {
    return (

        <Card.Header title={disabled ? "Disabled" : (!isEditable ? "Update details" : "Cancel")}
            className='bg text-info h5 d-flex justify-content-between align-items-center'>
            <span>{children}</span>
            <button disabled={disabled} onClick={() => {
                setisEditable(prevState => { return { ...prevState, [section]: !prevState[section] } })
            }} className={`btn btn-sm ${!isEditable ? "btn-success" : "btn-danger"}`} >

                {!isEditable && <RiEdit2Line style={icon_size_20} />}

                {isEditable && <GiCancel style={icon_size_20} />}
            </button>
        </Card.Header>

    )
}