import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    savedBlogs: {},
    savedCategories: {}
}


const blogSlice = createSlice({
    name: "blogs",
    initialState,
    reducers: {
        setLocalBlogs: (state, action) => {
            state.savedBlogs = action.payload.blogs;
        },
        setLocalCategories: (state, action) => {
            state.savedCategories = action.payload.categories;
        }
     }
})

export const { setLocalBlogs, setLocalCategories } = blogSlice.actions;

export default blogSlice.reducer;