import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import FormInput from '../UI/FormInput'
import { TbAddressBook, TbMapPinCode, TbPhoneCheck } from 'react-icons/tb'
import { MdDateRange, MdOutlineArrowDropDownCircle, MdOutlineLocationOn } from 'react-icons/md'
import DatePicker from 'react-datepicker'
import { IoWarningOutline } from 'react-icons/io5'
import { validateData } from '../../utils/helpers'
import { useDispatch } from 'react-redux'
import apiAxios from '../../axios/axios'
import Toaster from '../UI/Toaster'
import { login } from '../../store/auth/authSlice'

const Personal = ({ userDetails, isEditable = false, setisEditable }) => {

    const [user, setUser] = useState({ fullName: "", mobile: "", gender: "", dob: "", address: "", pincode: "", city: "", state: "", country: "" })
    const [userStatus, setUserStatus] = useState({})
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", message: "" })


    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setUser(prevState => { return { ...prevState, [name]: value } })
        setUserStatus(prevState => { return { ...prevState, [name]: value !== "" ? "" : [null, "This field is required"] } })
    }

    const setInitial = useCallback(async () => {
        setUser(prevState => { return { ...prevState, ...userDetails } })
    }, [userDetails])


    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        const validations = validateData(user);
        console.log(validations);
        if (validations) {
            setUserStatus(validations)
            setLoading(false);
            return;
        }

        try {
            const res = await apiAxios.patch("/account/", { ...user });
            if (res.status === 200) {
                setisEditable(prevState => {return {...prevState, personal: false}})
                setTimeout(() => {
                    dispatch(login({ userData: res.data?.data.user }));
                }, 2000);
            } else {
                const er = res.data.errors;
                const errors = Object.fromEntries(
                    Object.entries(er).map(([key, value]) => [key, [null, value]])
                );
                setUserStatus(errors)
            }

            setLoading(false);
            setToasterState({ isOpen: true, varient: res.data.success ? "success" : "danger", message: res.data.message })
        } catch (error) {

        }
    }



    useEffect(() => {
        setInitial()
    }, [setInitial])

    return (
        <>
            {toasterState.isOpen && <Toaster showToast={toasterState} hideToast={setToasterState} />}

            <Form
                method="post"
                id="UPDATE_DETAILS"
                className="update-user"
                autoComplete="off"
                onSubmit={onSubmitHandler}
            >
                <Row className="mb-3">
                    <FormInput
                        className='col-md-6'
                        name={"fullName"}
                        onChange={onChangeHandler}
                        value={user.fullName}
                        label={<><TbAddressBook />&nbsp; Full name</>}
                        placeholder="First Middle Last name"
                        status={userStatus?.fullName}
                        readOnly={!isEditable}
                    />

                    <FormInput
                        className='col-md-6'
                        type='number'
                        name={"mobile"}
                        onChange={onChangeHandler}
                        value={user.mobile}
                        label={<><TbPhoneCheck />&nbsp; Mobile No.</>}
                        placeholder="1234567890"
                        status={userStatus?.mobile}
                        readOnly={!isEditable}
                    />

                    <Form.Group as={Col} className='col-md-4 mb-3' controlId="gender">
                        <Form.Label><MdOutlineArrowDropDownCircle /> &nbsp;Select gender</Form.Label>
                        <Form.Select disabled={!isEditable} onChange={onChangeHandler} value={user?.gender} name='gender' className={``} >
                            <option value="">--SELECT--</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </Form.Select>
                        {userStatus?.gender?.[0] === null ?
                            <Form.Text className='warning'><IoWarningOutline style={{ width: "16px", height: "16px" }} /> &nbsp;{userStatus?.gender?.[1]}</Form.Text>
                            : ""
                        }
                    </Form.Group>

                    <Form.Group as={Col} className='col-md-4 mb-3' controlId="gender">
                        <Form.Label><><MdDateRange />&nbsp; Date of birth</></Form.Label>

                        <DatePicker
                            readOnly={!isEditable}
                            yearDropdownItemNumber={25}
                            scrollableYearDropdown
                            showYearDropdown
                            className='form-control'
                            placeholderText='25/01/1999'
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            selected={user.dob}
                            onChange={(date) => onChangeHandler({ target: { name: "dob", value: date } })}
                        />
                        {userStatus?.dob?.[0] === null ?
                            <Form.Text className='warning'><IoWarningOutline style={{ width: "16px", height: "16px" }} /> &nbsp;{userStatus?.dob?.[1]}</Form.Text>
                            : ""
                        }
                    </Form.Group>
                    <FormInput
                        className='col-md-8'
                        name={"address"}
                        onChange={onChangeHandler}
                        value={user.address}
                        label={<><MdOutlineLocationOn />&nbsp; Address.</>}
                        placeholder="House, lane, village"
                        status={userStatus?.address}
                        readOnly={!isEditable}
                    />

                    <FormInput
                        className='col-md-4'
                        type='number'
                        name={"pincode"}
                        onChange={onChangeHandler}
                        value={user.pincode}
                        label={<><TbMapPinCode />&nbsp; Pincode</>}
                        placeholder="123456"
                        status={userStatus?.pincode}
                        readOnly={!isEditable}
                    />

                    <FormInput
                        className='col-md-4'
                        name={"city"}
                        onChange={onChangeHandler}
                        value={user.city}
                        label={<><TbMapPinCode />&nbsp; City</>}
                        placeholder="City"
                        status={userStatus?.city}
                        readOnly={!isEditable}
                    />

                    <FormInput
                        className='col-md-4'
                        name={"state"}
                        onChange={onChangeHandler}
                        value={user.state}
                        label={<><TbMapPinCode />&nbsp; State.</>}
                        placeholder="State"
                        status={userStatus?.state}
                        readOnly={!isEditable}
                    />

                    <FormInput
                        className='col-md-4'
                        name={"country"}
                        onChange={onChangeHandler}
                        value={user.country}
                        label={<><TbMapPinCode />&nbsp; Country</>}
                        placeholder="Country"
                        status={userStatus?.country}
                        readOnly={!isEditable}
                    />
                </Row>
                {isEditable &&
                    <Button disabled={loading && true} type='submit' className='px-4'>
                        {loading && <><Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> Loading...</>}
                        {!loading && "Update"}
                    </Button>
                }
            </Form>
        </>
    )
}

export default Personal

// address: {
//     type: String,
// },
// city: {
//     type: String,
// },
// pincode: {
//     type: String,
// },
// state: {
//     type: String,
// },
// country: {
//     type: String,
// },
// dob: {
//     type: String
// },
