import React from 'react';
import { Button, Modal } from 'react-bootstrap';

/**
 * ConfirmationModal Component
 *
 * A reusable modal component that displays a popup dialog using React Bootstrap's Modal.
 * This component provides a customizable popup dialog with a title, content, and buttons for confirmation.
 *
 * @param {string} classes - CSS additional classes for styling the modal (default: "text-dark").
 * @param {string} size - Size variant for the modal (e.g., 'sm', 'lg', 'xl').
 * @param {function} handleClose - Function to handle closing the modal.
 * @param {array} show - Array representing [true/false, modalId] to control modal visibility.
 * @param {string} title - Title of the modal, displayed in the header.
 * @param {any} children - Children elements to be displayed in the modal's body.
 * @param {string} id - Id of the modal.
 * @param {function} onConfirm - Function to be called when the "Confirm" button is clicked.
 *
 * @returns {JSX.Element} - The rendered React element representing the ConfirmationModal component.
 */

const ConfirmationModal = ({ show, onClose, onConfirm, id, classes, title, onCustom, children, ...rest }) => {

    const handleConfirm = () => {
        onClose({ id: "", isShow: false });
        onConfirm();
    };

    return (
        <Modal
            centered
            show={show.id === id && show.isShow}
            onHide={() => onClose({ id: "", isShow: false })}
            backdrop="static"
            keyboard={false}
            dialogClassName={`${classes ? classes : ""}`}
            id={id}
            {...rest}
        >
            {title && <Modal.Header className='py-2 border-0'>
                <span>{title}</span>
            </Modal.Header>}
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer className='py-1 border-0'>
                {onConfirm && <>
                    <Button id='modal_confirm_btn' variant="danger" size='sm' onClick={handleConfirm}>
                        Confirm
                    </Button>
                    <Button id='modal_cancel_btn' variant="secondary" size='sm' onClick={() => onClose({ id: "", isShow: false })}>
                        Cancel
                    </Button>
                </>}

                {onCustom && onCustom}

            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
