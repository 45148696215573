import React, { useState } from 'react'

import { MdOutlineMailOutline } from 'react-icons/md'
import { RiLockPasswordLine } from 'react-icons/ri'
import { TbAddressBook, TbPhoneCheck } from "react-icons/tb"

import { Card, Form, Button, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { BE_HOST } from '../../constants'
import FormInput from '../UI/FormInput'
import apiAxios from '../../axios/axios'
import Toaster from '../UI/Toaster'
import { validateData } from '../../utils/helpers'

const Signup = () => {

    const [signupDetails, setSignupDetails] = useState({ fullName: "", mobile: "", email: "", password: "" })
    const [signupStatus, setSignupStatus] = useState({})
    const [loading, setLoading] = useState(false)
    const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", message: "" })

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setSignupDetails(prevState => { return { ...prevState, [name]: value } })
        setSignupStatus(prevState => { return { ...prevState, [name]: value !== "" ? "" : [null, "This field is required"] } })
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        const validations = validateData(signupDetails);
        if(validations){
            setSignupStatus(validations)
            setLoading(false);
            return;
        }

        try {
            const res = await apiAxios.post("/account/auth/signup", { ...signupDetails });
            if (res.status === 200) {
                setTimeout(() => {
                    window.location.href = "/auth";
                }, 5000);
            } else {
                const er = res.data.errors;
                const errors = Object.fromEntries(
                    Object.entries(er).map(([key, value]) => [key, [null, value]])
                );
                setLoading(false);
                setSignupStatus(errors)
            }

            setToasterState({ isOpen: true, varient: res.data.success ? "success" : "danger", message: res.data.message })
        } catch (error) {

        } 
    }

    return (
        <>
            {toasterState.isOpen && <Toaster showToast={toasterState} hideToast={setToasterState} />}

            <Card className='rounded mb-3'>
                <Card.Body className='rounded'>
                    <div className='logo text-center m-auto'>
                        <img src={BE_HOST + '/assets/ck/collegekatta-logo-colored.png'} alt="collegekatta.in" width="75%" classes="m-auto" />
                        <hr className='hrspan' />

                        <h3>Create An Account</h3>
                        <p>Already have an account? <Link className='text-warning' to="/auth">Signin</Link></p>

                    </div>
                    <hr className='hrspan' />
                    <div className="form-body">
                        <Form method='post' onSubmit={onSubmitHandler}>
                            <FormInput
                                name={"fullName"}
                                onChange={onChangeHandler}
                                value={signupDetails.fullName}
                                label={<><TbAddressBook />&nbsp; Full name</>}
                                placeholder="First Middle Last name"
                                status={signupStatus?.fullName}
                            />

                            <FormInput
                                name={"mobile"}
                                onChange={onChangeHandler}
                                value={signupDetails.mobile}
                                label={<><TbPhoneCheck />&nbsp; Mobile No.</>}
                                placeholder="1234567890"
                                status={signupStatus?.mobile}
                            />

                            <FormInput
                                type="email"
                                name={"email"}
                                onChange={onChangeHandler}
                                value={signupDetails.email}
                                label={<><MdOutlineMailOutline />&nbsp; Email Id.</>}
                                placeholder="user@domain.com"
                                status={signupStatus?.email}
                            />

                            <FormInput
                                type="password"
                                name={"password"}
                                onChange={onChangeHandler}
                                value={signupDetails.password}
                                label={<><RiLockPasswordLine />&nbsp; Password.</>}
                                placeholder="********"
                                status={signupStatus?.password}
                            />

                            <div className="mb-3">
                                <p>By clicking "signup" you agree to our &nbsp;
                                    <Link className='text-primary' to="/t_c#terms_of_service">Terms of Service</Link> &amp; &nbsp;
                                    <Link className='text-primary' to="/t_c#privacy_policy">Privacy Policy</Link>&nbsp;
                                </p>
                            </div>
                            <div className="text-center">
                                <Button disabled={loading && true} type="submit" className="btn btn-primary w-75 py-2">
                                {loading && <><Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> Loading...</>}
                                    {!loading && "Sign up"}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Card.Body>
            </Card>
        </>

    )
}


export default Signup