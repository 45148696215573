import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import apiAxios from '../../axios/axios';
import Loader from '../UI/Loader';
import { Badge, Card } from 'react-bootstrap';
import BlogForm from '../UI/BlogForm';
import { validateData } from '../../utils/helpers';
import Toaster from '../UI/Toaster';

const BlogUpdate = () => {
  const { blogId } = useParams();
  const navigate = useNavigate();
  const [blogDetails, setBlogDetails] = useState({ title: "", category: "", inshort: "", content: "", featuredImage: '', tags: "" });
  const [blogStatus, setBlogStatus] = useState({})
  const [loading, setLoading] = useState(true)
  const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", message: "" })


  const onChangeHandler = async (e) => {
    const { name, value, type } = e.target;

    if (type === 'file') {
      setBlogDetails((prevState) => { return { ...prevState, [name]: e.target.files[0] } });
    } else {
      setBlogDetails((prevState) => { return { ...prevState, [name]: value }; });
    }
    setBlogStatus(prevState => { return { ...prevState, [name]: value !== "" ? "" : [null, "This field is required"] } })

  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    !blogDetails.featuredImage && delete blogDetails.featuredImage
    const validations = validateData(blogDetails);
    if (validations) {
      setLoading(false);
      setBlogStatus(validations)
      setToasterState({ isOpen: true, varient: "danger", message: "Enter all details" })
      return;
    }

    try {
      const data = new FormData();
      for (const [key, value] of Object.entries(blogDetails)) {
        data.append(key, value);
      }

      const res = await apiAxios.patch("/account/blogs", data);

      if (res.status === 200) {
        setTimeout(() => {
          navigate("/blogs")
        }, 2000);
      } else {
        const er = res.data.errors;
        const errors = Object.fromEntries(
          Object.entries(er).map(([key, value]) => [key, [null, value]])
        );
        setBlogStatus(errors)
      }

      setLoading(false);
      setToasterState({ isOpen: true, varient: res.data.success ? "success" : "danger", message: res.data.message })

    } catch (error) {
      setLoading(false);
    }
  }

  const getBlog = useCallback(async () => {
    try {
      const res = await apiAxios.get(`/account/blogs/${blogId}`);

      if (res.status === 200) {
        const blog = res.data?.data.blog;
        setBlogDetails({ blogId: blog._id, inshort: blog.inshort, slug: blog.slug, title: blog.title, category: blog.category._id, content: blog.content, featuredImage: '', tags: blog.tags })
      }

    } catch (error) {

    }

    setLoading(false)
  }, [blogId])

  useEffect(() => {
    getBlog();
  }, [getBlog])

  return (
    <>
      {loading && <Loader />}
      {toasterState.isOpen && <Toaster showToast={toasterState} hideToast={setToasterState} />}
    
      <Card.Header className='bg text-info h5 d-flex justify-content-between align-items-center'>
        <span>
          {blogDetails.title}
        </span>
        <span>
          <Badge bg={blogDetails.status?.toLowerCase() === "published" ? "success" : "secondary"}>{blogDetails.status}</Badge>
        </span>
      </Card.Header>
      <Card.Body>

        {
          blogDetails.title ?
            <BlogForm loading={loading} blogDetails={blogDetails} blogStatus={blogStatus} onChangeHandler={onChangeHandler} onSubmitHandler={onSubmitHandler} />
            : "No blog"
        }

      </Card.Body>

    </>
  )
}

export default BlogUpdate