import React, { useState } from 'react'
import { Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import { MdOutlineCategory } from 'react-icons/md'
import { icon_size_20 } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import apiAxios from '../../axios/axios'
import Toaster from '../UI/Toaster'
import { setLocalCategories } from '../../store/blogs/blogSlice'
import { getCategories } from '../../utils/helpers'

const Categories = () => {
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch();
    const savedCategories = useSelector(state => state.blogs.savedCategories);
    const [category, setCategory] = useState("")
    const [openCatForm, setOpenCatForm] = useState(false)
    const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", message: "" })

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoader(true);
        try {
            const res = await apiAxios.post("/account/blogs/category", { category });
            if (res.status === 200) {
                setCategory("")
                setOpenCatForm(false);
                dispatch(setLocalCategories({ categories: await getCategories() }));
            }

            setToasterState({ isOpen: true, varient: res.data.success ? "success" : "danger", message: res.data.message })
            setLoader(false);
        } catch (error) {

        }
    }

    const savedCategoriesTr = savedCategories && savedCategories.map((cat, index) => <tr key={index}>
        <td>{index + 1}</td>
        <td>{cat.name}</td>
        <td width={"150px"}>
            <Button variant='outline-warning' size='sm' disabled>Update</Button>
        </td>
    </tr>)

    return (
        <>
            {toasterState.isOpen && <Toaster showToast={toasterState} hideToast={setToasterState} />}

            <Card.Header className='bg text-info h5 d-flex align-items-center justify-content-between'>
                <span><MdOutlineCategory style={icon_size_20} /> Categories</span>
                <Button onClick={() => setOpenCatForm(presState => !presState)} variant={openCatForm ? "danger" : "warning"} size='sm'>{openCatForm ? "Cancel" : "Add New"}</Button>
            </Card.Header>

            <Card.Body className='my-4'>
                {openCatForm &&
                    <div>
                        <Form onSubmit={onSubmitHandler}>
                            <Form.Group as={Row} className="mb-3" controlId="category">
                                <Form.Label column sm="2">
                                    Category
                                </Form.Label>
                                <Col sm="7">
                                    <Form.Control required name='category' onChange={(e) => setCategory(e.target.value)} value={category} placeholder='Enter category' />
                                </Col>

                                <Col sm="2">
                                    <Button type='submit'>
                                        {loader && <><Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> Loading...</>}
                                        {!loader && "Add"}
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Form>
                        <hr />
                    </div>
                }
                <Table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {savedCategoriesTr}
                    </tbody>
                </Table>

            </Card.Body>

        </>
    )
}

export default Categories