import React, { useRef, useState } from 'react'
import { Badge, Card, Form } from 'react-bootstrap'
import Tooltips from './UI/ToolTips'
import { NavLink, useLocation } from 'react-router-dom';
import { handleScroll } from '../utils/helpers';
import Toaster from './UI/Toaster';
import Loader from './UI/Loader';
import apiAxios from '../axios/axios';
import { BE_HOST } from '../constants';

const LeftHeader = ({ userDetails }) => {
    const navContainerRef = useRef();
    const [scrollPosition, setScrollPosition] = useState(0)
    const { pathname } = useLocation();
    const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", message: "" })
    const [loading, setLoading] = useState(false)

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        // Check if selected file is an image
        const reader = new FileReader();
        reader.onload = (e) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = async () => {
                if (image.width > 0 && image.height > 0) {
                    await uploadImageToServer(file);
                } else {
                    event.target.value = null;
                    setToasterState({ isOpen: true, varient: "danger", message: "Selected file is not an image, Only png, jpeg, jpg files are supported!" })
                }
            };
        };
        reader.readAsDataURL(file);
    };

    const uploadImageToServer = async (file) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('avatarPic', file);
            const res = await apiAxios.post('account/avatar', formData)

            if (res.status === 200) {
                document.getElementById('profilepic').src = res.data?.data.avatarUrl;
            }

            setToasterState({ isOpen: true, varient: res.data.success ? "success" : "danger", message: res.data.message })

        } catch (error) { }
        setLoading(false)
    };


    return (
        <>
            {toasterState.isOpen && <Toaster showToast={toasterState} hideToast={setToasterState} />}
            {loading && <Loader />}

            <div style={{backgroundImage: `url(${BE_HOST}/assets/u/background_banner_collegekatta.png)`}} className='bg-cover'></div>
            <Card.Body className='my-5'>
            <div className="profile-d">
                <div className="text-center">
                    {/* profile picture */}
                    <Tooltips message={"Click on image to upload profile picture"}>
                        <div className="rounded-circle m-auto position-relative overflow-hidden bg-dark" style={{ width: "150px", height: "150px" }}>
                            <Form.Group controlId="formFile" className="mb-3 ">
                                <Form.Label style={{ cursor: "pointer" }} >
                                    <img id="profilepic"
                                        src={userDetails.avatar}
                                        alt={userDetails.fullName}
                                        width="100%"
                                        height="100%"
                                    />
                                </Form.Label>
                                <Form.Control onChange={(e) => handleFileChange(e)} type="file" className="d-none" accept="image/png, image/jpeg, image/jpg" />
                            </Form.Group>
                        </div>
                    </Tooltips>
                </div>
            </div>
            <p className="text-center my-3">
                {userDetails.fullName}
            <small> <Badge bg='success'>{userDetails.role}</Badge> </small>
            </p>
            
            <hr className='my-3' />
            <div className='position-relative left-nav_link pb-4 pb-md-0'>

                <div ref={navContainerRef} className="position-relative list-group border-0 mt-3 list-group-flush flex-lg-column flex-row overflow-x-scroll">
                    <NavLink
                        className="list-group-item list-group-item-action text-nowrap me-2 me-lg-0"
                        activeclassname="active"
                        to="profile"
                        aria-current={pathname.includes("profile") ? "page" : undefined}
                    >
                        Profile
                    </NavLink>
                    {
                        userDetails.role === "ADMIN" && <NavLink
                        className="list-group-item list-group-item-action text-nowrap me-2 me-lg-0"
                        activeclassname="active"
                        to="admin"
                        aria-current={pathname.includes("admin") ? "page" : undefined}
                    >
                        Admin
                    </NavLink>
                    }
                    <NavLink
                        className="list-group-item list-group-item-action text-nowrap me-2 me-lg-0"
                        activeclassname="active"
                        to="blogs"
                        aria-current={pathname.includes("blogs") ? "page" : undefined}
                    >
                        Blogs
                    </NavLink>

                </div>
                <span onClick={() => handleScroll(">", scrollPosition, setScrollPosition, navContainerRef)} className='d-block d-lg-none position-absolute end-0 bottom-0 btn text-white btn-sm btn-secondary p-1 py-0'>{">>"}</span>
                <span onClick={() => handleScroll("<", scrollPosition, setScrollPosition, navContainerRef)} className='d-block d-lg-none position-absolute start-0 bottom-0 btn text-white btn-sm btn-secondary p-1 py-0'>{"<<"}</span>
            </div>
            </Card.Body>
        </>
    )
}

export default LeftHeader