import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { IoWarningOutline } from "react-icons/io5";

const TinyMCEEditor = forwardRef((props, ref, placeholder = "Write here...",) => {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      let e = {
        target: {
          name: "content",
          value: editorRef.current.getContent(),
        },
      };
      return e;
    }
  };

  useImperativeHandle(ref, () => ({
    resetContent: () => {
      editorRef.current.setContent("");
    },
  }));

  return (
    <>
    <Editor
    initialValue={props?.content}
    className="Hello"
      onChange={(e) => props.onChange(log())}
      onInit={(evt, editor) => { editorRef.current = editor;}}
      init={{
        promotion: false,
        // cloudChannel: null, // Disable Tiny Cloud integration
        // content_style: "body { font-family: times new roman, times, serif; font-size:14pt;background:#1E354D; }",
        height: 500,
        paste_data_images: false,
        body_class: `editor ${props?.status?.[0] === null && "text-danger"}`,
        placeholder: placeholder,
        plugins: "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars link codesample table charmap pagebreak nonbreaking insertdatetime advlist lists wordcount help charmap emoticons",
        menubar: "edit view insert format tools table help",
        toolbar: "undo redo | bold italic underline strikethrough | restoredraft fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak emoticons | link codesample | code preview",
        font_size_formats: "8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt",
        toolbar_sticky: true,
        importcss_append: true,
        toolbar_mode: "",
        skin: "oxide-dark",
        content_css: "dark", // default, dark , writer , document
        // branding: false,

        // Auto save
        autosave_ask_before_unload: true,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        codesample_global_prismjs: true
      }}
    />
    {props?.status?.[0] === null ?
      <small className='warning'><IoWarningOutline style={{ width: "16px", height: "16px" }} /> &nbsp;{props?.status?.[1]}</small>
      : ""
  }
  </>
  );
});

export default TinyMCEEditor;
