import React, { useState } from 'react'
import { Alert, Button, Spinner, } from 'react-bootstrap'
import { Form } from 'react-router-dom'
import FormInput from '../UI/FormInput'
import { MdOutlineMailOutline } from 'react-icons/md'

const Email = ({ email, isEditable = false }) => {
    const [loading] = useState(false)

    return (
        <>

            <Alert className='py-2' variant={"warning"}>
                Currenlty disabled!
            </Alert>
            <Form
                method="post"
                id="UPDATE_DETAILS_EMAIL"
                className="update-email"
                autoComplete="off"
            >
                <FormInput
                    className='col-7'
                    type='email'
                    name={"email"}
                    // onChange={onChangeHandler}
                    value={email}
                    label={<><MdOutlineMailOutline />&nbsp; Email Id.</>}
                    placeholder="user@domain.com"
                    // status={signupStatus?.fullName}
                    readOnly={!isEditable}

                />

                {isEditable &&
                    <Button disabled={loading && true} type='submit' className='px-4'>
                        {loading && <><Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> Loading...</>}
                        {!loading && "Update"}
                    </Button>}

            </Form>
        </>
    )
}

export default Email