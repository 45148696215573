import React from 'react'
import { Alert } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

const Admin = () => {
    const userDetails = useSelector(state => state.auth.userData)

    return (
        <>

            {userDetails.role !== "ADMIN" && <Alert variant='warning' className='py-2'>Permission denide!</Alert>}

            {userDetails.role === "ADMIN" && <Outlet />}
        </>
    )
}

export default Admin