import React from 'react'
import { Card } from 'react-bootstrap'
import { MdDashboardCustomize } from 'react-icons/md'
import { icon_size_20 } from '../../constants'

const Dashboard = () => {
  return (
    <>

      <Card.Header className='bg h4'><MdDashboardCustomize style={icon_size_20} /> Dashboard</Card.Header>
      <Card.Body className='my-4'>
        <div className='row'>
          <div className='col-md-3'>Users</div>
          <div className='col-md-3'>New blogs</div>
          {/* <div className='col-md-3'></div> */}

        </div>
      </Card.Body>
    </>
  )
}

export default Dashboard