import React, { useState } from 'react'
import { searchFilter } from '../../utils/helpers';
import { Form } from 'react-bootstrap';

const SearchFilter = ({originalList, setNewList}) => {
    const [search_q, setSearch_q] = useState("");

    const filter = (e) => {
        setSearch_q(e.target.value)
        const filterD = searchFilter(originalList, e.target.value);
        setNewList(filterD)
    }

    return (
        <Form.Group className="mb-3 col-4 ms-auto" controlId="searchFormInp">
        <Form.Control type="email" value={search_q} onChange={filter} placeholder='Search...' />
      </Form.Group>
    )
}

export default SearchFilter