import React, { useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import FormInput from '../UI/FormInput'
import { RiLockPasswordLine } from 'react-icons/ri'
import Toaster from '../UI/Toaster'
import { validateData } from '../../utils/helpers'
import apiAxios from '../../axios/axios'

const Password = ({ isEditable = false, setisEditable }) => {
    const [password, setPassword] = useState({ oldPassword: "", newPassword: "", newPassword_re: "" })
    const [passwordStatus, setPasswordStatus] = useState({})
    const [loading, setLoading] = useState(false)
    const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", message: "" })


    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setPassword(prevState => { return { ...prevState, [name]: value } })
        setPasswordStatus(prevState => { return { ...prevState, [name]: value !== "" ? "" : [null, "This field is required"] } })
    }


    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        const validations = validateData(password);
        console.log(validations);
        if (validations) {
            setPasswordStatus(validations)
            setLoading(false);
            return;
        }

        if(password.newPassword !== password.newPassword_re){
            setPasswordStatus(prevState => { return { ...prevState, newPassword_re: ["", "Passwords do not match!"]}})
            return;
        }

        try {
            const res = await apiAxios.post("/account/reset-password", { ...password });
            if (res.status !== 200) {
                const er = res.data.errors;
                const errors = Object.fromEntries(
                    Object.entries(er).map(([key, value]) => [key, [null, value]])
                );
                setPasswordStatus(errors)
            }

            if(res.status === 200){
                setPassword({newPassword: "", newPassword_re: ""})
                setisEditable(prevState => {return {...prevState, password: false}})
            }
            
            setLoading(false);
            setToasterState({ isOpen: true, varient: res.data.success ? "success" : "danger", message: res.data.message })
        } catch (error) {

        }
    }

    return (
        <Form method='post' onSubmit={onSubmitHandler}>
            {toasterState.isOpen && <Toaster showToast={toasterState} hideToast={setToasterState} />}

            <FormInput

                type='password'
                className={"col-md-7"}
                name={"oldPassword"}
                onChange={onChangeHandler}
                value={password.oldPassword}
                label={<><RiLockPasswordLine />&nbsp; Old Password</>}
                placeholder="********"
                readOnly={!isEditable}
                status={passwordStatus?.oldPassword}
            />

            <FormInput
                type='password'
                className={"col-md-7"}
                name={"newPassword"}
                onChange={onChangeHandler}
                value={password.newPassword}
                label={<><RiLockPasswordLine />&nbsp; New Password</>}
                placeholder="********"
                readOnly={!isEditable}
                status={passwordStatus?.newPassword}
            />

            <FormInput
                type='password'
                className={"col-md-7"}
                name={"newPassword_re"}
                onChange={onChangeHandler}
                value={password.newPassword_re}
                label={<><RiLockPasswordLine />&nbsp; Confirm Password</>}
                placeholder="********"
                readOnly={!isEditable}
                status={passwordStatus?.newPassword_re}
            />

            {isEditable &&
                <Button disabled={loading && true} type='submit' className='px-4'>
                    {loading && <><Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> Loading...</>}
                    {!loading && "Update"}
                </Button>
            }
        </Form>
    )
}

export default Password