import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import { IoLogOutOutline } from "react-icons/io5";
import apiAxios from '../axios/axios';
import Loader from './UI/Loader';
import { NavDropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LoggedInProfileNav = ({ classes = '' }) => {
  const { isLoggedIn, userData } = useSelector(state => state.auth)
const navigate = useNavigate()

  const [loader, setLoader] = useState(false)

  const logoutHandler = async () => {
    setLoader(true)
    try {
      const res = await apiAxios.post("/account/auth/logout");
      if (res.status === 200) {
        localStorage.removeItem("refreshToken");
        setTimeout(() => {
          window.location.href = "/auth";

        }, 2000);
      } else {
        setLoader(false)
      }
    } catch (error) {

    }

  }
  return (
    <>
      {loader && <Loader />}
      {!isLoggedIn &&
        <div className={classes}>
          <Button onClick={() => navigate("/auth")} className='mt-1' size='sm' variant="primary" >Sign In <IoLogOutOutline style={{ width: "20px", height: "20px" }} /></Button>
        </div>
      }

      {
        isLoggedIn &&
        <NavDropdown
          title={<>
            <span className='avtar-by-letter me-1'>
              {userData.avatar ?
                <img alt={userData.fullName[0]?.toUpperCase()} src={userData.avatar} width={"100%"} className='overflow-hidden rounded-circle' />
                : userData.fullName[0]?.toUpperCase()}
            </span>
            <span className='d-inline-block overflow-hidden' style={{ maxWidth: "50px" }}>{userData.fullName.split(' ')[0]}</span></>}
          id={`offcanvasNavbarDropdown-expand-${"md"}`}
          className={`account-header-dropdown d-inline-flex align-items-center p-2 rounded ${classes}`}
        >
          <NavDropdown.Item href="/profile">Account</NavDropdown.Item>
          <NavDropdown.Divider />
          <span className='dropdown-item'>
            <Button onClick={logoutHandler} className='mt-1' size='sm' variant="danger" >Sign out <IoLogOutOutline style={{ width: "20px", height: "20px" }} /></Button>
          </span>
        </NavDropdown>
      }
    </>

  )
}

export default LoggedInProfileNav