import React from 'react'
import { Outlet } from "react-router-dom"
import TopHeader from './TopHeader'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';
import LeftHeader from './LeftHeader';
import EmailVerificationToken from './auth/EmailVerificationToken';
import { useSelector } from 'react-redux';

const Layout = () => {
    const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });
    const userDetails = useSelector(state => state.auth.userData);


    return (
        <>
            {/* Top header */}
            <TopHeader />
            <Container fluid={!isLargeScreen} className='mb-5' style={{ marginTop: "4rem" }}>
                {/* left side */}
                <Row>
                    <Col lg={4}>
                        <Card className='mt-lg-0 mt-3'>
                            <LeftHeader userDetails={userDetails} />
                        </Card>
                    </Col>
                    <Col lg={8}>
                        <Card className='mt-lg-0 mt-3' id='main'>
                            {!userDetails.isEmailVerified &&
                                <Card.Header className='bg px-0 pt-0'>
                                    <EmailVerificationToken />
                                </Card.Header>
                            }
                            <Outlet />
                        </Card>
                    </Col>
                </Row>
                {/* center main body */}
            </Container>

        </>
    )
}

export default Layout