import React, { useState } from 'react'

import { MdOutlineMailOutline } from 'react-icons/md'

import { Card, Form, Button, Spinner } from 'react-bootstrap'
import { Link, useSearchParams } from 'react-router-dom'
import { BE_HOST } from '../../constants'
import FormInput from '../UI/FormInput'
import apiAxios from '../../axios/axios'
import { useDispatch } from 'react-redux'
import { login } from '../../store/auth/authSlice'
import { RiLockPasswordLine } from 'react-icons/ri'
import Toaster from '../UI/Toaster'
import { validateData } from '../../utils/helpers'
const Signin = () => {
    const [searchParams] = useSearchParams();

    const [loginDetails, setLoginDetails] = useState({ email: "", password: "" })
    const [loading, setLoading] = useState(false)
    const [loginStatus, setLoginStatus] = useState({})
    const dispatch = useDispatch()
    const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", message: "" })

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setLoginDetails(prevState => { return { ...prevState, [name]: value } })
        setLoginStatus(prevState => { return { ...prevState, [name]: value !== "" ? "" : [null, "This field is required"] } })
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        const validations = validateData(loginDetails);
        if(validations){
            setLoginStatus(validations)
            setLoading(false);
            return;
        }

        try {
            const res = await apiAxios.post("/account/auth", { ...loginDetails });
            if (res.status === 200) {
                localStorage.setItem("refreshToken", res.data?.data.refreshToken)
                setTimeout(() => {
                    dispatch(login({ userData: res.data?.data.user }));
                        window.location.href = searchParams.get('redirect') ? searchParams.get('redirect') : "/";
                    
                }, 2000);
            } else {
                const er = res.data.errors;
                const errors = Object.fromEntries(
                    Object.entries(er).map(([key, value]) => [key, [null, value]])
                );
                setLoading(false);
                setLoginStatus(errors)
            }

            setToasterState({ isOpen: true, varient: res.data.success ? "success" : "danger", message: res.data.message })
        } catch (error) {

        } 
    }


    return (
        <>
            {toasterState.isOpen && <Toaster showToast={toasterState} hideToast={setToasterState} />}
            <Card className="rounded mb-3">
                <Card.Body className='rounded'>
                    <div className='logo text-center m-auto'>
                        <img src={BE_HOST + '/assets/ck/collegekatta-logo-colored.png'} alt="collegekatta.in" width="75%" classes="m-auto" />
                        <hr className='hrspan' />

                        <h3>Welcome back</h3>
                        <p>Don't have an account? <Link className='text-warning' to="/auth/signup">Signup</Link></p>

                    </div>
                    <hr className='hrspan' />
                    <div className="form-body">
                        <Form method='post' onSubmit={onSubmitHandler}>

                            <FormInput
                                type={"email"}
                                name={"email"}
                                onChange={onChangeHandler}
                                value={loginDetails.email}
                                label={<><MdOutlineMailOutline />&nbsp; Email Id.</>}
                                placeholder="email@domain.com"
                                status={loginStatus?.email}
                            />

                            <FormInput
                                type={"password"}
                                name={"password"}
                                onChange={onChangeHandler}
                                value={loginDetails.password}
                                label={<><RiLockPasswordLine />&nbsp; Password</>}
                                placeholder="********"
                                status={loginStatus?.password}
                            />
                            <div className="mb-3 text-danger text-end">
                                <Link to="/auth/forgot-password" className='text-danger text-decoration-underline'>Forgot password?</Link>
                            </div>
                            <div className="text-center">
                                <Button disabled={loading && true} type="submit" className="w-75 py-2">
                                    {loading && <><Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> Loading...</>}
                                    {!loading && "Sign in"}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Card.Body>
            </Card>
        </>

    )
}

export default Signin