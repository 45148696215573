import React, { useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Loader from './components/UI/Loader'
import { useDispatch } from 'react-redux'
import { login, logout } from './store/auth/authSlice'
import apiAxios from './axios/axios'

const App = () => {

    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    const getLoggedUser = useCallback(async () => {
        try {
            const res = await apiAxios.get("/account");

            if (res.status === 200) {
                dispatch(login({ userData: res.data?.data.user }))
            } else {
                const res = await apiAxios.post("/account/auth/refresh-token", {refreshToken: localStorage.getItem("refreshToken")});
                if(res.status === 200){
                    dispatch(login({ userData: res.data?.data.user }))
                    localStorage.setItem("refreshToken", res.data?.data.refreshToken)
                }
            }

        } catch (error) {
            dispatch(logout());
        }

        setLoading(false)
    }, [dispatch])

    useEffect(() => {
        getLoggedUser();
    }, [getLoggedUser])

    return loading ? <Loader /> : <Outlet />
}

export default App