import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Tooltips = ({placement = "top", message, children}) => {
    return (
        <OverlayTrigger
            key={placement}
            placement={placement}
            overlay={
                <Tooltip id={`tooltip-${placement}`}>
                    {message}
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    )
}

export default Tooltips