import React, { useCallback, useEffect, useState } from 'react'
import { Badge, Button, Card, Table } from 'react-bootstrap';
import { MdOutlineEditNote, MdOutlineFormatListBulleted, MdOutlineRemoveRedEye } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchFilter from '../UI/SearchFilter';
import { icon_size_20 } from '../../constants';

const BlogsList = () => {

  const [blogsFilterd, setBlogsFiltered] = useState({})
  const savedBlogs = useSelector(state => state.blogs.savedBlogs)
  const navigate = useNavigate()
  const getBlogs = useCallback(async () => {
    if (savedBlogs && savedBlogs.length > 0) {
      setBlogsFiltered(savedBlogs)
    }
    setTimeout(() => {
      document.getElementById('main').scrollIntoView({ behavior: 'smooth' });
      window.location.hash = 'main';
  }, 0);

  }, [savedBlogs])



  useEffect(() => {

    getBlogs();

  }, [getBlogs])

  const blogsListTable = blogsFilterd.length ? blogsFilterd.map((blog, index) => <tr key={index}>
    <td>{index + 1}</td>
    <td>{blog.title}</td>
    <td>{blog.category}</td>
    <td>{blog.createdAt}</td>
    <td>{blog.publishedAt}</td>
    <td><Badge bg="secondary">{blog.views}</Badge> / <Badge bg="secondary">{blog.likes}</Badge></td>
    <td><Badge bg={blog.status?.toLowerCase() === "published" ? "success" : "secondary"}>{blog.status}</Badge></td>
    <td className='d-flex justify-content-between align-items-center'>
      <Button onClick={() => navigate(`/blogs/view/${blog._id}`)} variant='outline-success' size='sm' className='p-1 py-0 mx-1'>
        <MdOutlineRemoveRedEye style={icon_size_20} />
      </Button>
      <Button onClick={() => navigate(`/blogs/update/${blog._id}`)} variant='outline-warning' size='sm' className='p-1 py-0 mx-1'>
        <MdOutlineEditNote style={icon_size_20} />
      </Button>
    </td>

  </tr>) : <tr><td colSpan={8} className='text-center text-danger'>No blogs!</td></tr>

  return (
    <>
      <Card.Header className='bg text-info h5'><MdOutlineFormatListBulleted style={icon_size_20} /> List of uploaded blogs</Card.Header>

      <Card.Body className='my-4'>
        <SearchFilter originalList={savedBlogs} setNewList={setBlogsFiltered} />
        <Table variant='dark' responsive hover bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Category</th>
              <th>Created At</th>
              <th>Published At</th>
              <th>Views / Likes</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {blogsListTable}
          </tbody>
        </Table>
      </Card.Body>
    </>
  )
}

export default BlogsList