import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Card, Nav } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { setLocalBlogs, setLocalCategories } from '../../store/blogs/blogSlice';
import apiAxios from '../../axios/axios';
import Loader from '../UI/Loader';

const Blogs = () => {
    const { pathname } = useLocation();
    const [loader, setLoader] = useState(true)
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.auth.userData);
    const { savedBlogs, savedCategories } = useSelector(state => state.blogs)
    const navigate = useNavigate();
    const fetchBlogsAndCategories = useCallback(async () => {
        if ((savedBlogs && savedBlogs.length > 0) && (savedCategories && savedCategories.length > 0)) {
            setLoader(false);
            return;
        }

        if (userDetails.role !== "BLOGGER" && userDetails.role !== "ADMIN") { setLoader(false); return; }

        try {
            const [blogsRes, categoriesRes] = await Promise.all([
                apiAxios.get("/account/blogs"),
                apiAxios.get("/blogs/categories")
            ]);

            if (blogsRes?.status === 200) {
                dispatch(setLocalBlogs({ blogs: blogsRes.data?.data.blogs }));
            }

            if (categoriesRes?.status === 200) {
                dispatch(setLocalCategories({ categories: categoriesRes.data?.data.categories }));
            }
        } catch (error) { }

        setLoader(false);
    }, [dispatch, navigate]);

    useEffect(() => {
        fetchBlogsAndCategories();
    }, [fetchBlogsAndCategories]);


    return (
        <>

            {loader && <Loader />}

            {userDetails.role === "BLOGGER" ||
                userDetails.role === "ADMIN" ?
                <>
                    <Card.Body>
                        <Nav variant="tabs" >
                            <Nav.Item>
                                <NavLink
                                    className="nav-link"
                                    activeclassname="active"
                                    to="list"
                                    aria-current={pathname.includes("list") ? "page" : undefined}
                                >
                                    Uploaded blogs
                                </NavLink>
                            </Nav.Item>

                            <Nav.Item>
                                <NavLink
                                    className="nav-link"
                                    activeclassname="active"
                                    to="new"
                                    aria-current={pathname.includes("new") ? "page" : undefined}
                                >
                                    New blog
                                </NavLink>
                            </Nav.Item>
                            {pathname.includes("blogs/view") &&
                                <Nav.Item>
                                    <NavLink
                                        className="nav-link"
                                        activeclassname="active"
                                        to="view"
                                        aria-current={pathname.includes("view") ? "page" : undefined}
                                    >
                                        Blog Details
                                    </NavLink>
                                </Nav.Item>}
                            {pathname.includes("blogs/update") &&
                                <Nav.Item>
                                    <NavLink
                                        className="nav-link"
                                        activeclassname="active"
                                        to="update"
                                        aria-current={pathname.includes("update") ? "page" : undefined}
                                    >
                                        Update blog
                                    </NavLink>
                                </Nav.Item>}

                            {userDetails.role === "ADMIN" &&
                                <Nav.Item>
                                    <NavLink
                                        className="nav-link"
                                        activeclassname="active"
                                        to="categories"
                                        aria-current={pathname.includes("categories") ? "page" : undefined}
                                    >
                                        Categories
                                    </NavLink>
                                </Nav.Item>}
                        </Nav>
                    </Card.Body>
                    {!loader && <Outlet />}
                </>
                : <Card.Header className='bg px-0 pt-0'>
                    <Alert className='py-2' variant='secondary'>

                        <Alert.Heading>Permission denied!</Alert.Heading>
                        <p>
                            It looks like you don't have permission to upload blogs at the moment.
                            If you're passionate about writing and would like to contribute to our platform,
                            <br />we'd love to hear from you!
                            Please <NavLink to="/support"> contact us </NavLink> to get started.
                        </p>

                    </Alert>
                </Card.Header>
            }
        </>
    )
}

export default Blogs